var ELEMENT_RE = /[\w-]+/g,
  ID_RE = /#[\w-]+/g,
  CLASS_RE = /\.[\w-]+/g,
  ATTR_RE = /\[[^\]]+\]/g,
  // :not() pseudo-class does not add to specificity, but its content does as if it was outside it
  PSEUDO_CLASSES_RE = /\:(?!not)[\w-]+(\(.*\))?/g,
  PSEUDO_ELEMENTS_RE = /\:\:?(after|before|first-letter|first-line|selection)/g;
// convert an array-like object to array
function toArray(list) {
  return [].slice.call(list);
}

function _find(string, re) {
  var matches = string.match(re);
  return matches ? matches.length : 0;
}

function calculateScore(selector) {
  var score = [0, 0, 0],
    parts = selector.split(" "),
    part,
    match;
  //TODO: clean the ':not' part since the last ELEMENT_RE will pick it up
  while (((part = parts.shift()), typeof part == "string")) {
    // find all pseudo-elements
    match = _find(part, PSEUDO_ELEMENTS_RE);
    score[2] += match;
    // and remove them
    match && (part = part.replace(PSEUDO_ELEMENTS_RE, ""));
    // find all pseudo-classes
    match = _find(part, PSEUDO_CLASSES_RE);
    score[1] += match;
    // and remove them
    match && (part = part.replace(PSEUDO_CLASSES_RE, ""));
    // find all attributes
    match = _find(part, ATTR_RE);
    score[1] += match;
    // and remove them
    match && (part = part.replace(ATTR_RE, ""));
    // find all IDs
    match = _find(part, ID_RE);
    score[0] += match;
    // and remove them
    match && (part = part.replace(ID_RE, ""));
    // find all classes
    match = _find(part, CLASS_RE);
    score[1] += match;
    // and remove them
    match && (part = part.replace(CLASS_RE, ""));
    // find all elements
    score[2] += _find(part, ELEMENT_RE);
  }
  return parseInt(score.join(""), 10);
}

export function matchedRules(el) {
  var sheets = document.styleSheets,
    ret = [];
  el.matches =
    el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector || el.oMatchesSelector;
  for (var i in sheets) {
    if (sheets[i].href) continue;
    var rules = sheets[i].rules || sheets[i].cssRules;
    for (var r in rules) {
      let rule = (rules[r].selectorText || "").replace(/:(hover)/g, "");
      if (rule && el.matches(rule)) {
        ret.push(rules[r].selectorText);
      }
    }
  }

  ret = ret.reverse().sort((a, b) => calculateScore(b) - calculateScore(a));

  return ret;
}
