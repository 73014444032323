import * as S from "./state";
import { matchedRules } from "./css";

export const getScope = (s) => {
  let out = [];
  let temp = s;
  let path = [];
  while (temp.p) {
    let { id, ifid, linkid, type } = temp.meta;
    if (type == "if") {
      path.push({ id });
      path.push({ id: ifid });
    } else if (type == "each") {
      path.push({ id });
    } else if (type == "cmp") {
      path.push({ id: linkid });
    }
    temp = temp.p;
  }
  path.reverse();
  console.log(path);
  return path;

  // get all the parents and type  of parent.
  // if parent is of type each then get index, if it is of type ifblock then get the id.
  // get all the variables available at a level and variables that are getting used.
  // people can edit a code by opening it and then a full view code editor will open up.
  // where they can work on it without issues. move the window to any corner and more.
  // scope will provide type of object and basic information. so people can explore the object if needed.
  // people can run sample code if needed. to check.
};

const miniScope = (s) => {
  let out = {};
  let d = s.__data;
  while (d) {
    Object.keys(d).forEach((k) => {
      if (k[0] != "_") {
        if (typeof d[k] == "function") {
          out[k] = d[k].toString().slice(0, 200);
        } else if (typeof d[k] == "object") {
          out[k] = JSON.stringify(d[k])?.slice(0, 200);
        } else {
          out[k] = d[k];
        }
        if (out[k]?.length == 200) {
          out[k] += "...";
        }
      }
    });
    d = d._p;
  }
  return out;
};

const cmpDependency = (s, line) => {
  let ins = {};
  Object.keys(defs.ins).forEach((k) => {
    if (defs.ins[k].length > 0) {
      ins[parseInt(k.replace("cmp", ""))] = 1;
    }
  });
  let out = {};
  let all = [];
  let id = line;
  let ifb = {};
  let cblocks = [];
  while (s) {
    ifb = { ...ifb, ...(s.ifb || {}) };
    let m = s.meta;
    cblocks.push({ cmpid: parseInt(m.name.replace("cmp", "")), id: m.id });
    if (s.name) {
      let cmpid = parseInt(s.name.replace("cmp", ""));
      if (ins[cmpid]) {
        delete ins[cmpid];
        out[cmpid] = { cmpid, id, ifb: {}, depth: all.length };
        all.push(cmpid);
      }
      if (out[cmpid]) {
        out[cmpid].ifb = { ...out[cmpid].ifb, ...ifb };
      }
      ifb = {};
      id = s._pid;
    }
    s = s.p;
  }
  all.reverse();
  return {
    open: out,
    all: [...all, ...Object.keys(ins).map((o) => parseInt(o))],
    cblocks,
  };
};

var last_postdata = "";

export const postData = ({ interval = false, sel }) => {
  if (sel) {
    let [a, b] = sel.getAttribute("class").split("cls");
    let cmpid = parseInt(
      sel
        .getAttribute("class")
        .split(" ")
        .filter((o) => o.startsWith("cmp"))[0]
        .slice(3)
    );
    let line = parseInt(b);
    let s = sel.__s;
    let rules = matchedRules(sel);
    let files = cmpDependency(s, line);
    let path = getScope(s);
    let scope = miniScope(s);
    let data = { type: "markup", line, cmpid, path, rules, scope, files };
    let datastr = JSON.stringify(data);
    data.interval = interval;
    if (!interval || last_postdata != datastr) {
      window.parent.postMessage(data, S.origin);
      last_postdata = datastr;
    }
  }
};
