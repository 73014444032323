import "./handle";
import "./helpers";
import "./state";
import { origin } from "./state";

// import { add, sub } from "./test.rs";
// console.error(add(2, 3), sub(3, 4));

if (module.hot) {
  module.hot.accept();
}

window.__interact = true;

let render = () => {
  let __updates = window.__updates;
  Object.keys(__updates).forEach((k) => {
    if (__updates[k] && __ins[k]) {
      let obj = __ins[k];
      console.log("calling update");
      obj.cmp.p(obj);
    }
  });
  requestAnimationFrame(render);
};
requestAnimationFrame(render);

window.Nav = () => {
  let obj = { s: document.createElement("div"), history: [], page: "" };
  let s = obj.s;
  obj.a = (t) => t.appendChild(s);
  obj.set = (page) => {
    try {
      obj.history.forEach((o) => o.d());
    } catch (e) {}
    obj.history = [];

    let div = document.createElement("nav-wrap");
    div.style = "display:block;position:absolute;left:0px;top:0px;width:100%;height:100%;z-index:10;overflow:auto;";
    s.innerHTML = "";
    s.appendChild(div);
    let elem = defs[page].new({});
    elem.a(div);
    obj.history.push(elem);
    obj.page = page;
    elem.s.holder = div;
  };
  return obj;
};
window.defs = {};
// window.nav = Nav();
// window.nav.a(document.querySelector("#preview"));

window.openpage = (page) => {
  if (window.__current) {
    window.__current.d();
  }
  let elem = defs[page].new({});
  window.__current = elem;
  elem.a(document.body);
};

// window.history.replaceState(null, document.title, "/");
window.parent.postMessage({ type: "loaded" }, origin);

const sendUrl = (url = null) => {
  let nUrl = window.location.href;
  if (nUrl != url) {
    window.parent.postMessage({ type: "url", url: nUrl }, origin);
  }
  requestAnimationFrame(() => sendUrl(nUrl));
};
sendUrl();
