// export const origin =
//   decodeURIComponent(window.location.href.split("origin=")[1])?.split("#")[0]?.split("&")[0] ||
// "https://local.promisapp.com";
export const origin = window.location.origin.startsWith("https://lpreview")
  ? "https://local.promisapp.com"
  : "https://app.promisapp.com";
console.error("ORIGIN is", origin);
window.__updates = {};
window.__insid = 1;
window.__ins = {};
