import * as S from "./scope";
import { origin } from "./state";

var interact = false;
var close_controls;
var highlight;
var selected;

const reset_highlight = ({ post = true }) => {
  if (selected) {
    selected.removeAttribute("selected");
  }
  window.elem = highlight;
  selected = highlight;
  if (selected) {
    selected.setAttribute("selected", true);

    let [a, b] = selected.getAttribute("class").split("cls");
    let cmpid = parseInt(
      selected
        .getAttribute("class")
        .split(" ")
        .filter((o) => o.startsWith("cmp"))[0]
        .slice(3)
    );
    // let line = parseInt(b);
    if (post) {
      S.postData({ sel: selected });
    }
  } else {
    document.querySelector("#cur_class").textContent = ``;
  }
};

const updateStyleStr = (elem) => {
  let { left, top, width, height } = elem.getBoundingClientRect();
  // style_str = `${width}x${height}`;
};
const showGuide = (line) => {
  if (line == 0) {
    highlight = null;
    let guide = document.querySelector(".guide");
    guide.style = "display:none;";
    return;
  }
  let o = document.querySelector(`.preview .${"cmp" + page_id}-cls` + line);
  highlight = o;
  if (!o) return;
  let { left, top, width, height } = o.getBoundingClientRect();
  let guide = document.querySelector(".guide");
  updateStyleStr(o);
  // debugger
  guide.style = `left:${left}px;top:${top}px;width:${width}px;height:${height}px;`;
};

const highlightElement = (elem) => {
  if (highlight == elem || !window._controls) return;
  // lastelem = elem;
  if (!elem) {
    showGuide(0);
    return;
  }
  let pelem = elem;
  while (pelem) {
    if (pelem.getAttribute && pelem.getAttribute("class")?.indexOf("cls") >= 0) {
      break;
    }
    pelem = pelem.parentNode;
  }
  if (!pelem) {
    showGuide(0);
    return;
  }
  highlight = pelem;
  let { left, top, width, height } = pelem.getBoundingClientRect();
  let guide = document.querySelector(".guide");
  updateStyleStr(pelem);
  guide.style = `left:${left}px;top:${top}px;width:${width}px;height:${height}px;`;
  // document.querySelector('#markup-editor input').focus()
};

const highlightElementOld = (elem) => {
  if (highlight == elem || !window._controls) return;
  if (!elem) {
    showGuide(0);
    return;
  }
  // if(!document.querySelector('#preview-cont').contains(elem)){
  //   if(!document.querySelector('#markup-editor').contains(elem)){
  //     showGuide(0)
  //   }
  //   return;
  // }
  for (let i = 0; i < 3; i++) {
    if (elem && (!elem.className?.indexOf || elem.className.indexOf("cls") == -1)) {
      elem = elem.parentNode;
    } else {
      break;
    }
  }
  if (!elem || !elem.className.indexOf || elem.className.indexOf("cls") == -1) {
    showGuide(0);
    return;
  }

  // if(elem.className.indexOf('cmp-'+page_id)!=-1) return;
  highlight = elem;
  let { left, top, width, height } = elem.getBoundingClientRect();
  let guide = document.querySelector(".guide");
  updateStyleStr(elem);
  guide.style = `left:${left}px;top:${top}px;width:${width}px;height:${height}px;`;
  // document.querySelector('#markup-editor input').focus()
};

const updateControls = (on = true) => {
  if (on) {
    window._controls = true;
    document.body.setAttribute("controls", "on");
  } else {
    window._controls = false;
    document.body.setAttribute("controls", "off");
  }
};

window.addEventListener("message", (data) => {
  if (data.origin != origin) return;
  let message = data.data;
  if (message.type == "suggestions") {
    return window.parent.postMessage({ type: "suggestions", data: [] }, origin);
    try {
      let word = message.code;
      let code = `
        let out = []
        let extra = []
        let c = ${word};
        if(c instanceof Array){
          out = Object.getOwnPropertyNames(Object.getPrototypeOf(c))
        } else if(c instanceof Function){
          out = []
        } else {
          out = Object.getOwnPropertyNames(c)
          extra = Object.getOwnPropertyNames(Object.getPrototypeOf(c))
          while(c?._p){
            c = c._p
            out = [...out,...Object.getOwnPropertyNames(c)]
          }
        }
        [...out,...extra].filter(o => o[0]!='_')
      `;
      let data = window.elem?.__s.__r(code);
      console.log("suggestions for", code, data);
      window.parent.postMessage({ type: "suggestions", data }, origin);
    } catch (e) {
      console.error(e);
      window.parent.postMessage({ type: "suggestions", data: [] }, origin);
    }
  } else if (message.type == "runtime") {
    try {
      let data = window.elem?.__s.__r(message.code);
      if (typeof data == "function") {
        data = "function";
      }
      window.parent.postMessage({ type: "runtime", data }, origin);
    } catch (e) {
      window.parent.postMessage({ type: "runtime", data: e }, origin);
    }
  } else if (message.type == "zoom") {
    document.body.style["zoom"] = message.data + "%";
  } else if (message.type == "controls") {
    let { cmd } = message;
    if (cmd == "home") {
      window.location.hash = "/";
    } else if (cmd == "back") {
      window.history.back();
    } else if (cmd == "forward") {
      window.history.forward();
    } else if (cmd == "refresh") {
      window.location.reload();
    }
  } else if (message.type == "controls.on") {
    updateControls(true);
  } else if (message.type == "controls.off") {
    updateControls(false);
  } else if (message.type == "cmpupdate") {
    console.error("Component Update Called");
    // console.log(message)
    let code = message.code;
    Object.keys(code).forEach((k) => {
      let c = code[k];
      window.evals = window.evals || {};
      if (!window.evals[k]) {
        window.evals[k] = (code) => eval(code);
      }
      window.evals[k](c + `\n//# sourceURL=promis-${k}`);
    });
    setTimeout(() => S.postData(true));
  } else if (message.type == "nodesel") {
    let { cmpid, id, temp } = message;
    try {
      let elem = document.querySelector(".cmp" + cmpid + "-cls" + id);
      if (selected) {
        elem =
          selected.querySelector(".cmp" + cmpid + "-cls" + id) ||
          selected.closest(".cmp" + cmpid + "-cls" + id) ||
          document.querySelector(".cmp" + cmpid + "-cls" + id);
      }

      if (temp) {
        highlightElement(elem);
      } else {
        highlightElement(null);
        highlight = elem;
        reset_highlight({ post: false });
      }
    } catch (e) {
      console.error(e);
    }
  } else if (message.type == "navset") {
    let ins = defs?.ins || {};
    Object.keys(ins).map((k) => {
      ins[k].forEach((obj) => {
        if (obj?.d) {
          obj.d();
        }
      });
    });
    let page = message.page;
    try {
      window.openpage("cmp" + page);
    } catch (e) {
      setTimeout(() => window.openpage("cmp" + page), 1000);
    }
  } else if (message.type == "navupdate") {
    let page = message.page;
    console.log(defs);
    nav.update(true);
  } else if (message.type == "styleupdate") {
    let code = message.code;
    Object.keys(code).forEach((k) => {
      let elem = document.querySelector("#__promis_style #" + k);
      if (!elem) {
        elem = document.createElement("style");
        elem.id = k;
        document.querySelector("#__promis_style").appendChild(elem);
      }
      elem.textContent = code[k];
    });
  }
});

const handleWindowClick = (e) => {
  if (!window._controls) return;
  // if(e.metaKey || interact || close_controls) return;
  // if(!document.querySelector('#preview-cont').contains(e.target)) return;
  // document.querySelector('#markup-editor input').focus()
  e.preventDefault();
  e.stopImmediatePropagation();
  if (highlight) {
    let { left, top, width, height } = highlight.getBoundingClientRect();
    let { x, y, screenX, screenY } = e;
    if (!(left < x < left + width && top < y < top + height)) return;
    try {
      reset_highlight({});
    } catch (e) {
      console.error(e);
    }
  } else {
    window.parent.postMessage({ type: "markup", line: 0, cmpid: 0 }, origin);
  }
};

const handleMousemove = (e) => {
  if (e.metaKey || interact) {
    showGuide(0);
    return;
  }
  highlightElement(e.target);
};
const handleKeyUp = (e) => {
  // console.log(e)
  let { key, code, shiftKey, ctrlKey, metaKey, repeat } = e;
  if (code == "MetaLeft") {
    window.parent.postMessage({ type: "metaleft" }, origin);
  }
};
const handleKeyDown = (e) => {
  let { key, shiftKey, ctrlKey, metaKey, repeat } = e;
  if (metaKey && key == "\\") {
    window.parent.postMessage({ type: "hardcontrols" }, origin);
    e.preventDefault();
    e.stopPropagation();
  }
};

window.addEventListener("keyup", handleKeyUp, true);
window.addEventListener("keydown", handleKeyDown, true);
window.addEventListener("click", handleWindowClick, true);
window.addEventListener(
  "mouseout",
  () => {
    showGuide(0);
  },
  true
);
window.addEventListener("mousemove", handleMousemove, true);
window.addEventListener(
  "wheel",
  () => {
    showGuide(0);
  },
  true
);

let last_sent = 0;
setInterval(() => {
  if (window._changes || performance.now() - last_sent > 1000) {
    S.postData(true);
    window._changes = false;
    last_sent = performance.now();
  }
}, 100);

updateControls(true);
