window.$$ = {};

$$.importJS = (url) => {
  window.imported = window.imported || {};
  if (window.imported[url]) return;
  window.imported[url] = 1;

  let s = document.createElement("script");
  s.src = url;
  document.head.appendChild(s);
};

$$.importCSS = (url) => {
  window.imported = window.imported || {};
  if (window.imported[url]) return;
  window.imported[url] = 1;

  let s = document.createElement("link");
  s.href = url;
  s.rel = "stylesheet";
  document.head.appendChild(s);
};

$$.addCSS = (style) => {};

$$.slidebox = (i) => {
  return { transform: `translateX(\$\{i*93}%) scale(\$\{1-Math.abs(.2*i)})` };
};

$$.pilebox = (i) => {
  return { transform: `translateX(\$\{i*100}%)` };
};

$$.tostr = (obj) => {
  if (!obj) return "";
  return Object.keys(obj)
    .map((k) => `\$\{k\}:\$\{obj[k]\};`)
    .join("");
};

$$.toCamel = (str) => str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace("-", "").replace("_", ""));

$$.incr = function (li, ctx) {
  const update = (item, d) => {
    if (ctx[item].type == "state") {
      let old = ctx[item].p;
      ctx[item].p += (d * 1) / 600;
      ctx[item].p = Math.max(0, Math.min(1, ctx[item].p));
      d -= (ctx[item].p - old) * 600;
    } else {
      let old = ctx[item];
      ctx[item] += d;
      ctx[item] = Math.max(0, Math.min(ctx[item], 1000));
      d -= ctx[item] - old;
    }
    return d;
  };
  const change = (d) => {
    if (d > 0) {
      for (let i = 0; i < li.length; i++) {
        if (d <= 0) break;
        d = update(li[i], d);
      }
    } else {
      for (let i = li.length - 1; i >= 0; i--) {
        if (d >= 0) break;
        d = update(li[i], d);
      }
    }
  };
  return change;
};

$$.states = function (sts) {
  const set = () => {};
  const to = () => {};
  const add = () => {};
  const next = () => {};
  let l = 0;
  let r = 1;
  let p = 0;

  const snap = () => {
    return c, p, pr;
  };
  return { set, to, add, snap, next, l, r, p, type: "state" };
};

$$.element = (name) => {
  if (
    ["svg", "g", "rect", "path", "text", "circle", "defs", "image", "clipPath", "tsspan", "polyline"].indexOf(name) !=
    -1
  ) {
    return document.createElementNS("http://www.w3.org/2000/svg", name);
  }
  return document.createElement(name);
};

$$.text = (text) => document.createTextNode(text);
$$.detach = (node) => {
  if (node.forEach) {
    node.forEach((o) => o?.parentNode?.removeChild(o));
  } else {
    node?.parentNode?.removeChild(node);
  }
};
$$.noop = () => {};

$$.insert = (node, li, anchor = null) => {
  li.map((o) => {
    if (o.hasOwnProperty("s")) {
      if (o.a) o.a(node, anchor);
    } else {
      try {
        node.insertBefore(o, anchor || null);
      } catch (e) {
        console.error(e);
        console.log(o);
      }
    }
  });
};
$$.append = (node, li) => {
  li.map((o) => {
    if (o.hasOwnProperty("s")) {
      let anchor = o.anchor;
      if (!anchor) {
        anchor = $$.text("");
      }
      node.appendChild(anchor);
      if (o.a) o.a(node, anchor);
    } else {
      try {
        node.appendChild(o);
        if (o.errorblk) {
          let anchor = $$.text("");
          node.appendChild(anchor);
          o.target = node;
          o.anchor = anchor;
        }
      } catch (e) {
        console.error(e);
        console.log(o);
      }
    }
  });
};

$$.interpolate = (l, r, p) => {
  return `calc(\$\{l\}*\$\{1-p\} + \$\{r\}*\$\{p\})`;
};

$$.phandler = {
  get(obj, key) {
    while (true) {
      if (obj.hasOwnProperty(key)) {
        // if(typeof(obj[key])=='function'){
        //   return obj[key]()
        // }
        return obj[key];
      }
      if (obj.hasOwnProperty("_p")) {
        obj = obj._p;
      } else {
        break;
      }
    }
    return undefined;
  },
  set(obj, key, val) {
    while (true) {
      if (obj.hasOwnProperty(key)) {
        // console.error('Updated',key)
        if (typeof obj[key] != "function") {
          obj[key] = val;
        }
        if (obj.on && obj.on[key]) {
          obj.on[key](val);
        }
        __updates[obj.__id] = 1;
        return true;
      }
      if (obj.hasOwnProperty("_p")) {
        obj = obj._p;
      } else {
        break;
      }
    }
  },
};

$$.stylecode = (a, ak, elem, style, stycode) => {
  let sty = "";
  let frames = [{}, {}];
  let changing = null;
  if (stycode) {
    let out = stycode(frames, sty, changing);
    changing = out.changing;
    sty = out.sty;
  }
  if (changing) {
    if (!a || ak != changing) {
      a = elem.animate(frames, { duration: 100, easing: "linear", fill: "forwards" });
      a.pause();
      a.currentTime = 0;
      ak = changing;
    }
    a.currentTime = changing.p * 100;
  } else {
    if (a) {
      a.cancel();
    }
    a = null;
  }
  sty += style;
  return sty;
};
